<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form-model-item>
              <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
            </a-form-model-item>
            <a-form layout="inline">
              <a-form-model-item>
                <a-range-picker @change="changeDate" />
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.on_site_admin_id" placeholder="请选择工程师">
                  <a-select-option v-for="(item, index) in engineerList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" @click="search">搜索</a-button>
              </a-form-model-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">

                <template slot="state" slot-scope="text, record">
                  <a-tag color="blue" v-if="text == '1'">未巡检</a-tag>
                  <a-tag color="green" v-if="text == '2'">已巡检</a-tag>
                </template>
                <template slot="installDate" slot-scope="text, record">
                  <span>{{record.start_time}} ~ {{record.end_time}}</span>
                </template>
                <template slot="action" slot-scope="text, record">
                  <a-dropdown >
                    <a class="ant-dropdown-link" @click.stop.prevent="e => e.preventDefault()">
                      更多操作
                      <a-icon type="down"/>
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-button type="link" @click="openDetail(record)" icon="eye">详情</a-button>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>

              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>


    <a-modal title="详情" :width="800" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading"
             :visible="detailVisible" :footer="null" @cancel="cancel">
      <table class="cu-table" id="print-table">
        <tr><td class="field-left">客户公司</td><td>{{record.company_name}}</td></tr>
        <tr><td class="field-left">客户名称</td><td>{{record.custom_name}}</td></tr>
        <tr><td class="field-left">联系电话</td><td>{{record.custom_mobile}}</td></tr>
        <tr>
          <td class="field-left">巡检状态</td>
          <td>
            <span v-if="record.state == '1'">未巡检</span>
            <span v-if="record.state == '2'">已巡检</span>
          </td>
        </tr>
        <tr><td class="field-left">巡检产品</td>
          <td>
            <div v-for="(item, index) in record.product_json" :key="index">
              <div><span>产品名称: </span><span>{{item.name}}</span></div>
              <div><span>机器型号: </span><span>{{item.machine_model}}</span></div>
              <div><span>供货单位: </span><span>{{item.delivery_unit}}</span></div>
              <div><span>品牌: </span><span>{{item.report_name}}</span></div>
            </div>
          </td>
        </tr>
        <tr><td class="field-left">巡检开始时间</td><td>{{record.start_time}}</td></tr>
        <tr><td class="field-left">巡检结束时间</td><td>{{record.end_time}}</td></tr>
        <tr><td class="field-left">巡检时间</td><td>{{record.onsite_time}}</td></tr>
        <tr><td class="field-left">备注</td><td>{{record.content}}</td></tr>
        <tr><td class="field-left">创建时间</td><td>{{record.create_time}}</td></tr>
      </table>
      <div style="margin: 20px;display: flex;justify-content: center"><a-button v-print="'#print-table'">打印</a-button></div>
    </a-modal>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        columns: [
          {
            title: '客户公司',
            dataIndex: 'company_name'
          },
          {
            title: '客户名称',
            dataIndex: 'custom_name'
          },
          {
            title: '联系电话',
            dataIndex: 'custom_mobile',
            width: 150
          },
          {
            title: '巡检状态',
            dataIndex: 'state',
            scopedSlots: {customRender: 'state'},
            width: 100
          },
          {
            title: '巡检开始~结束时间',
            dataIndex: 'installDate',
            scopedSlots: {customRender: 'installDate'},
            width: 250
          },
          {
            title: '巡检时间',
            dataIndex: 'onsite_time',
            scopedSlots: {customRender: 'onsite_time'},
            width: 150
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            width:200
          },
          {
            title: '操作',
            width: 150,
            scopedSlots: {customRender: 'action'}
          },
        ],

        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit:20,
          start_time: '',
          end_time: '',
          on_site_admin_id: undefined,
          key: ''
        },
        list: [],
        adminList: [],
        engineerList: [],
        detailVisible: false,
        record: {},
      }
    },
    mounted() {
      this.getList();
      this.getAdminList();
      this.getInstallationEngineerList();
    },
    methods: {
      /**
       * 搜索时间区间
       * @author wheat
       * */
      changeDate(e, s) {
        this.page.start_time = s[0];
        this.page.end_time = s[1];
      },
      /**
       * @author 打开详情
       * */
      openDetail(record) {
        this.detailVisible = true;
        this.record = record;
        this.detailVisible = true;
        this.record['product_json'] = JSON.parse(this.record['product_json']);
        this.record['qr'] = process.env.VUE_APP_API + '/admin/form/installation/detailQRcode?id=' + record.id;
      },
      /**
       * 搜索
       * @author wheat
       * */
      search() {
        this.getList();
      },

      /**
       * 获取列表
       * @author wheat
       * */
      async getList() {
        let res = await Api.List(this.page)
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },
      /**
       * 获取员工列表
       * @author wheat
       * */
      async getAdminList() {
        let res = await Api.GetAdminList({});
        this.adminList = res.list;

      },
      /**
       * 获取工程师列表
       * @author wheat
       * */
      async getInstallationEngineerList() {
        let res = await Api.InstallationEngineerList({});
        this.engineerList = res.list;
      },
      /**
       * 分页切换
       * @author wheat
       * */
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      },
      /**
       * 关闭
       * @author lhx
       * */
      cancel(){
        this.detailVisible = false;
      },
    }
  }
</script>

<style scoped>
.cu-table
{
  border-collapse:collapse;
  width: 100%;
}

.cu-table,th, td
{
  border: 1px solid #000000;
  padding: 10px;
}
.field-left {
  width: 120px;
}
</style>
